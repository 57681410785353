<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="68px">
      <el-form-item v-if="isSuperAdmin" label="用户：" prop="userId">
        <el-select
            v-model="queryParams.userId"
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="请输入用户名，支持模糊搜索"
            :remote-method="queryUserInfo"
            :loading="queryUserLoading"
            style="width: 240px">
          <el-option
              v-for="item in userInfoList"
              :key="item.id"
              :label="item.username"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="任务名" prop="taskName">
        <el-input v-model="queryParams.taskName" placeholder="任务名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTryOnTasksV2">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 220px)" style="width: 100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="username" label="用户名" width="100"></el-table-column>
      <el-table-column prop="task_code" label="任务码" width="100"></el-table-column>
      <el-table-column prop="task_name" label="任务名" width="100"></el-table-column>
      <el-table-column prop="task_status" label="任务状态" width="100">
        <template #default="scope">
          <el-tag :type="getTaskStatusTag(scope.row.task_status)">{{
              getTaskStatusText(scope.row.task_status)
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="task_status" label="提示词" width="200">
        <template #default="scope">
          <el-text v-if="scope.row.task_param && scope.row.task_param.prompt" class="mx-1">
            {{scope.row.task_param.prompt}}
          </el-text>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" width="100" label="提交时间"/>
      <el-table-column width="200" label="模特图">
        <template #default="scope">
          <el-image v-if="scope.row.task_param && scope.row.task_param.person_image_url"
                    :src="scope.row.task_param.person_image_url"
                    :key="scope.row.task_code"
                    fit="contain"
                    class="try-on-result-image"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.5"
                    preview-teleported
                    :preview-src-list="[scope.row.task_param.person_image_url]"
          >
          </el-image>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="top_garment_url" width="200" label="上衣图">
        <template #default="scope">
          <el-image v-if="scope.row.task_param && scope.row.task_param.top_garment_image_url"
                    :src="scope.row.task_param.top_garment_image_url"
                    :key="scope.row.task_code"
                    fit="contain"
                    class="try-on-result-image"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.5"
                    preview-teleported
                    :preview-src-list="[scope.row.task_param.top_garment_image_url]"
          ></el-image>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column width="200" label="下衣图">
        <template #default="scope">
          <el-image v-if="scope.row.task_param && scope.row.task_param.lower_garment_image_url"
                    :src="scope.row.task_param.lower_garment_image_url"
                    :key="scope.row.task_id"
                    fit="contain"
                    class="try-on-result-image"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.5"
                    preview-teleported
                    :preview-src-list="[scope.row.task_param.lower_garment_image_url]"
          ></el-image>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cover" width="200" label="封面图">
        <template #default="scope">
          <el-image v-if="scope.row.cover && scope.row.cover.cover_url"
                    :src="scope.row.cover.cover_url"
                    :key="scope.row.task_code"
                    fit="contain"
                    class="try-on-result-image"
                    :zoom-rate="1.2"
                    :max-scale="5"
                    :min-scale="0.5"
                    preview-teleported
                    :preview-src-list="[scope.row.cover.origin_cover_url]"
          ></el-image>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="cover" width="200" label="结果图集">
        <template #default="scope">
          <el-image v-if="scope.row.result_images && scope.row.result_images.length > 0"
                    :src="scope.row.result_images[0].image_url"
                    :key="scope.row.task_code"
                    fit="contain"
                    class="try-on-result-image"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.4"
                    preview-teleported
                    :preview-src-list="scope.row.result_images.map(item => item.image_url)"
          ></el-image>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="200"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; gap: 10px; align-items: center;">
            <el-button type="primary" size="small" @click.prevent="handleEdit(scope.row)">
              任务详情
            </el-button>
            <el-button type="danger" size="small" @click.prevent="handleDelete(scope.row)">
              删除任务
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="getTryOnTasksV2"/>
    <!--图片预览区域-->
    <div>
      <el-dialog v-model="dialogVisible" style="width: 620px; height: 860px">
        <el-image style="width: 600px; height: 800px" :src="dialogImageUrl" fit="contain">
        </el-image>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "luma-account",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      userInfoList: [],
      queryUserLoading: false,
      selectedIds: [],
      queryParams: {
        userId: null,
        pageNum: 1,
        pageSize: 10,
        taskId: "",
        taskName: "",
      },
      tableLoading: false,
      tableData: [],
      loading: false,
      total: 0,
      dialogVisible: false,
      dialogImageUrl: '',
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getTryOnTasksV2();
  },
  methods: {
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      this.queryUserLoading = true;
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        this.queryUserLoading = false;
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.queryUserLoading = false;
        console.error(error);
      });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getTryOnTasksV2() {
      if (this.isLogin) {
        this.tableLoading = true;
        axiosInstance.post('/api/ai-try-on/v2/query-task', {
          ...this.queryParams
        }).then(response => {
          this.tableLoading = false;
          if (response.data && response.data.code === 0) {
            let data = response.data.data;
            this.total = data.total;
            this.tableData = data.results;
          }
        }).catch(error => {
          this.tableLoading = false;
          console.error(error)
        });
      } else {
        this.total = 0;
        this.tableData = [];
      }
    },
    getTaskStatusText(status) {
      switch (status) {
        case 'pending':
          return '排队中';
        case 'doing':
          return '执行中';
        case 'done_success':
          return '执行完成';
        case 'done_fail':
          return '执行失败';
        default:
          return '执行失败';
      }
    },
    getTaskStatusTag(status) {
      switch (status) {
        case 'pending':
          return 'info';
        case 'doing':
          return 'primary';
        case 'done_success':
          return 'success';
        case 'done_fail':
          return 'error';
        default:
          return 'warning';
      }
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

::v-deep .el-table th {
  text-align: center; /* 设置所有表头单元格的文本居中 */
}

.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid transparent; /* 默认透明的边框 */
  border-radius: 20px;
  width: 180px;
  height: 240px;
  margin: 10px;
  z-index: 1;
}
</style>