// src/router/index.js
import {createRouter, createWebHistory} from 'vue-router';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import DouyinLoginInfo from '@/components/douyin/DouyinAccountInfo.vue';
import LumaAccount from "@/components/luma/LumaAccount.vue";
import LumaWorkManager from "@/components/luma/LumaWorkManager.vue";
import DouyinWorkManager from "@/components/douyin/DouyinWorkManager.vue";
import DouyinWorkData from "@/components/douyin/DouyinWorkData.vue";
import MaterialRecordInfo from "@/components/content_manage/MaterialRecordInfo.vue";
import MaterialTagList from "@/components/content_manage/MaterialTagList.vue";
import AITryOneDemo from "@/components/ai_try_on/AITryOneDemo.vue";
import VideoMixSceneList from "@/components/video_mix/VideoMixSceneList.vue";
import VideoMixSceneConfig from "@/components/video_mix/VideoMixSceneConfig.vue";
import VideoMixTaskList from "@/components/video_mix/VideoMixTaskList.vue";
import AITryOnTaskList from "@/components/ai_try_on/AITryOnTaskList.vue";
import AITryOnUserList from "@/components/ai_try_on/AITryOnUserList.vue";
import AITryOnTaskV2List from "@/components/ai_try_on/AITryOnTaskV2List.vue";
import ComfyUIMachineInfoList from "@/components/comfyui/ComfyUIMachineInfoList.vue";
import ComfyUITaskList from "@/components/comfyui/ComfyUITaskList.vue";

const routes = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/',
        component: Home,
        redirect: '/ai-try-on/demo',
        children: [
            {
                path: '/douyin/account',
                component: DouyinLoginInfo,
                meta: {title: '抖音账号信息展示'}
            },
            {
                path: '/douyin/work',
                component: DouyinWorkManager,
                meta: {title: '抖音作品管理'}
            },
            {
                path: '/douyin/work-data',
                component: DouyinWorkData,
                meta: {title: '抖音作品数据'}
            },
            {
                path: '/video-create/account',
                component: LumaAccount,
                meta: {title: '视频自动生成账号信息展示'}
            },
            {
                path: '/video-create/work',
                component: LumaWorkManager,
                meta: {title: '视频自动生成作品管理'}
            },
            {
                path: '/content/material',
                component: MaterialRecordInfo,
                meta: {title: '素材管理'}
            },
            {
                path: '/content/tag',
                component: MaterialTagList,
                meta: {title: '标签管理'}
            },
            {
                path: '/content/work',
                component: MaterialRecordInfo,
                meta: {title: '成品管理'}
            },
            {
                path: '/ai-try-on/user',
                component: AITryOnUserList,
                meta: {title: 'AI换装用户信息'}
            },
            {
                path: '/ai-try-on/demo',
                component: AITryOneDemo,
                meta: {title: 'AI换装v1'}
            },
            {
                path: '/ai-try-on/task',
                component: AITryOnTaskList,
                meta: {title: '换装记录'}
            },
            {
                path: '/ai-try-on/v2',
                component: AITryOnTaskV2List,
                meta: {title: 'AI换装v2'}
            },
            {
                path: '/video-mix/scene-list',
                component: VideoMixSceneList,
                meta: {title: '视频混剪——场景列表'}
            },
            {
                path: '/video-mix/scene-config',
                component: VideoMixSceneConfig,
                meta: {title: '视频混剪——场景配置'}
            },
            {
                path: '/video-mix/task-list',
                component: VideoMixTaskList,
                meta: {title: '视频混剪——任务列表'}
            },
            {
                path: '/comfyui/machine',
                component: ComfyUIMachineInfoList,
                meta: {title: 'ComfyUI——服务器信息'}
            },
            {
                path: '/comfyui/task',
                component: ComfyUITaskList,
                meta: {title: 'ComfyUI——任务信息'}
            },
        ]
    }
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;