<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="68px">
      <el-form-item label="服务器名" prop="name">
        <el-input v-model="queryParams.name" placeholder="机器名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="在线状态" prop="status">
        <el-select v-model="queryParams.status" style="width: 200px" placeholder="请选择在线状态" clearable>
          <el-option label="在线" value="online"></el-option>
          <el-option label="离线" value="offline"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="启用状态" prop="enable">
        <el-select v-model="queryParams.enable" style="width: 200px" placeholder="请选择启用状态" clearable>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="停用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getMachineInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="clickAddMachineInfo">
          <el-icon>
            <Check/>
          </el-icon>
          <span>新增机器</span>
        </el-button>
        <el-button type="success" @click="clickRefreshMachineInfo">
          <el-icon>
            <Check/>
          </el-icon>
          <span>刷新服务器状态</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 220px)" style="width: 100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" width="100" label="服务器ID"></el-table-column>
      <el-table-column prop="name" label="服务器名" width="100"></el-table-column>
      <el-table-column prop="comfyui_url" label="ComfyUI HTTP地址" width="200"></el-table-column>
      <el-table-column prop="comfyui_ws_url" label="ComfyUI WS地址" width="200"></el-table-column>
      <el-table-column prop="queue_remaining" label="队列数量" width="100"></el-table-column>
      <el-table-column prop="status" label="机器在线状态" width="150">
        <template #default="scope">
          <el-tag :type="getMachineStatusTag(scope.row.status)">{{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="机器启用状态" width="150">
        <template #default="scope">
          <el-tag :type="getEnableTag(scope.row.enable)">{{ getEnableText(scope.row.enable) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" width="100" label="备注"/>
      <el-table-column prop="create_time" width="200" label="创建时间"></el-table-column>
      <el-table-column prop="update_time" width="200" label="更新时间"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="primary" @click.prevent="handleEdit(scope.row)">编辑
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="danger" @click.prevent="handleDelete(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getMachineInfo"/>
    <!--新增or更新区域-->
    <div>
      <el-dialog title="服务器信息"
                 v-model="dialogVisible"
                 width="40%"
                 :close-on-click-modal="false">
        <el-form
            :model="machineInfo"
            label-position="left"
            label-width="auto"
            style="max-width: 600px"
        >
          <el-form-item label="服务器名：" prop="name" required>
            <el-input v-model="machineInfo.name"
                      placeholder="请输入服务器名">
            </el-input>
          </el-form-item>
          <el-form-item label="ComfyUI Http URL：" prop="comfyui_url" required>
            <el-input v-model="machineInfo.comfyui_url"
                      placeholder="请输入comfyui http地址">
            </el-input>
          </el-form-item>
          <el-form-item label="ComfyUI WS URL：" prop="comfyui_ws_url" required>
            <el-input v-model="machineInfo.comfyui_ws_url"
                      placeholder="请输入comfyui ws地址">
            </el-input>
          </el-form-item>
          <el-form-item label="启用状态：" prop="enable" required>
            <el-select v-model="machineInfo.enable">
              <el-option label="启用" :value="1"></el-option>
              <el-option label="停用" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input
                placeholder="可选，请输入备注"
                v-model="machineInfo.remark"/>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveEditInfo" :loading="submitLoading">保存</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "luma-account",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      userInfoList: [],
      queryUserLoading: false,
      selectedIds: [],
      queryParams: {
        name: null,
        status: null,
        enable: null,
        page_num: 1,
        page_size: 10,
      },
      tableLoading: false,
      tableData: [],
      loading: false,
      total: 0,
      machineInfo: null,
      dialogVisible: false,
      dialogImageUrl: '',
      submitLoading: false,
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getMachineInfo();
  },
  methods: {
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      this.queryUserLoading = true;
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        this.queryUserLoading = false;
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.queryUserLoading = false;
        console.error(error);
      });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getMachineInfo() {
      this.tableLoading = true;
      axiosInstance.post('/api/comfyui/machine/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results;
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    getMachineStatusTag(status) {
      switch (status) {
        case 'online':
          return 'success';
        default:
          return 'warning';
      }
    },
    getEnableTag(status) {
      switch (status) {
        case 1:
          return 'success';
        case '1':
          return 'success';
        default:
          return 'warning';
      }
    },
    getEnableText(status) {
      switch (status) {
        case 1:
          return '启用';
        case '1':
          return '启用';
        default:
          return '停用';
      }
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    clickAddMachineInfo() {
      this.machineInfo = {
        id: null,
        name: null,
        comfyui_url: '',
        comfyui_ws_url: '',
        queue_remaining: 0,
        enable: 1,
        remark: '',
      };
      this.dialogVisible = true;
    },
    clickRefreshMachineInfo() {
      axiosInstance.post('/api/comfyui/machine/refresh', {}).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success("服务器状态刷新成功");
          this.getMachineInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
    },
    saveEditInfo() {
      this.submitLoading = true;
      axiosInstance.post('/api/comfyui/machine/update', {
        ...this.machineInfo
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.dialogVisible = false;
          this.getMachineInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
      });
    },
    handleEdit(row) {
      this.machineInfo = {...row};
      this.dialogVisible = true;
    },
    handleDelete(row) {
      let that = this;
      this.$confirm('此操作将彻底删除记录, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post('/api/comfyui/machine/delete', {
          "id": row.id
        }).then(response => {
          that.$message({
            type: 'success',
            message: '删除成功!'
          });
          that.getMachineInfo();
        }).catch(error => {
          that.$message({
            type: 'error',
            message: '删除失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },

  }
  ,
}
;
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}


.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid transparent; /* 默认透明的边框 */
  border-radius: 20px;
  width: 180px;
  height: 240px;
  margin: 10px;
  z-index: 1;
}
</style>